<template>
  <div class="third">
    <GModule>
      <GTitle name="第三方账号">
        <div class="fra hm-hd">
          <a-button class="clo1" @click="selectOpen=!0" v-if="authority.commit">添加</a-button>
          <a-modal
            v-model:visible="selectOpen"
            title="添加第三方"
            width="1200px"
            @ok="addSelect">
            <div class="select-list">
              <div class="item" v-for="item in selectpage">
                <a-checkbox v-model:checked="item.checked" :disabled="disableD(item.name)">{{item.name}}</a-checkbox>
              </div>
              <div class="item add" @click="selectOpen=!1,addOpen=!0">+ 添加新的第三方</div>
            </div>
          </a-modal>
          <a-modal
            v-model:visible="addOpen"
            title="添加新的第三方"
            width="800px"
            @ok="addNewSelect">
            <a-form :model="newRegForm">
              <a-form-item label="第三方名称">
                <a-input v-model:value="newRegForm.name" placeholder="请输入第三方名称" />
              </a-form-item>
              <a-form-item label="注册地址">
                <a-input v-model:value="newRegForm.reg_address" placeholder="请输入注册地址" />
              </a-form-item>
            </a-form>
          </a-modal>
          <a-button class="clo2" v-if="authority.export" @click="getExport">导出</a-button>
          <a-button class="clo5" v-if="authority.edit" @click="setSelect">提交</a-button>
          <!-- <a-button class="clo4">Button</a-button>
          <a-button class="clo5">Button</a-button>
          <a-button class="clo6">Button</a-button> -->
        </div>
      </GTitle>
    <a-table pagination="!1" sticky :columns="columns" :data-source="list" :scroll="{ x: 500 }" bordered>
      <template #bodyCell="{ column, text, record ,index}">
          <!-- <span v-text="column"></span>
          <span v-text="text"></span>
          <span v-text="record"></span> -->
          <template v-if="arrName.find(item=>item.name==column.key)">
                <a-input
                  v-if="authority.edit&&column.edit"
                  v-model:value="list[record.index-1][column.key]"
                  :placeholder="column.edit"
                  class="posInput"
                  />
                <template v-else>
                  <span @click="copy(text)">{{ text }}</span>
                </template>
          </template>
		  <template v-if="column.title == '第三方名称'">
			  <span @click="copy(record.name)">{{record.name}}</span>
		  </template>
		  <template v-if="column.title == '注册地址'">
		  	  <span @click="copy(record.reg_address)">{{record.reg_address}}</span>
		  </template>
		  <template v-if="column.key == 'operation'">
		  	  <div class="caozuo">
				  <a-popconfirm
				  	v-if="authority.del"
				  	title="确定删除此第三方账号吗?"
				  	ok-text="确定"
				  	cancel-text="取消"
				  	@confirm="delBtn(record.id,index)">
				  	<p>删除</p>
				  </a-popconfirm>
			  </div>
		  </template>
      </template>


    </a-table>

    </GModule>
  </div>
</template>
<script>
export default {
  name: 'Third',
  data(){
    return {
      inputValue:'',
      addOpen: !1,
      selectOpen:!1,
      selectpage: [],
      visible: !1,
      all: 1,
      list: [],
      // authority:[],
      newRegForm:{
        name:'',
        reg_address:'',
      },
      arrName:[
        {name:'username',placeholder:'输入账号'},
        {name:'password',placeholder:'输入密码'},
        {name:'developer_id',placeholder:'其他'},
        {name:'developer_pwd',placeholder:'其他'},
        {name:'original_id',placeholder:'其他'},
        {name:'merchant_key',placeholder:'其他'},
        {name:'extends0',placeholder:'其他'},
        {name:'extends1',placeholder:'其他'}
      ],
      columns: [
        {
          title: '序号',
          width: 40,
          dataIndex: 'index',
          key: 'index',
          fixed: 'left',
        }, {
          title: '第三方名称',
          width: 100,
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
          ellipsis: true,
        }, {
          title: '注册地址',
          width: 100,
          dataIndex: 'reg_address',
          key: 'reg_address',
          ellipsis: true,
          edit: '输入账号',
        }, {
          title: '账号',
          width: 100,
          dataIndex: 'username',
          key: 'username',
          ellipsis: true,
          edit: '输入账号',
          // slots: {
          //   customRender: 'username',
          // },
        }, {
          title: '密码',
          width: 100,
          dataIndex: 'password',
          key: 'password',
          ellipsis: true,
          edit: '输入密码',
          // slots: {
          //   customRender: 'password',
          // },
        }, {
          title: '其他',
          width: 100,
          dataIndex: 'developer_id',
          key: 'developer_id',
          ellipsis: true,
          edit: '其他',
          // slots: {
          //   customRender: 'developer_id',
          // },
        }, {
          title: '其他',
          width: 100,
          dataIndex: 'developer_pwd',
          key: 'developer_pwd',
          ellipsis: true,
          edit: '其他',
          // slots: {
          //   customRender: 'developer_pwd',
          // },
        }, {
          title: '其他',
          width: 100,
          dataIndex: 'original_id',
          key: 'original_id',
          ellipsis: true,
          edit: '其他',
          // slots: {
          //   customRender: 'original_id',
          // },
        }, {
          title: '其他',
          width: 100,
          dataIndex: 'merchant_key',
          key: 'merchant_key',
          ellipsis: true,
          edit: '其他',
          // slots: {
          //   customRender: 'merchant_key',
          // },
        }, {
          title: '其他',
          width: 100,
          dataIndex: 'extends0',
          key: 'extends0',
          ellipsis: true,
          edit: '其他',
          // slots: {
          //   customRender: 'extends0',
          // },
        }, {
          title: '备注',
          width: 100,
          dataIndex: 'extends1',
          key: 'extends1',
          ellipsis: true,
          edit: '备注',
          // slots: {
          //   customRender: 'extends1',
          // },
        // }, {
        //   title: '操作',
        //   key: 'operation',
        //   fixed: 'right',
        //   width: 60,
        }
      ],
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    proId() {
      return this.$store.state.proId
    },
    onProject(){
      var pro = this.projectList.find(item=>item.id==this.proId)
      return !this.all&&pro?pro:{name:'全部'}
    },
    disableD(){
      return name=>{
        return this.list.find(item=>item.name==name)
      }
    },
    authority(){
      var data = {};
      this.$store.state.params.forEach(item=>{
        data[item.name.split('/')[2]] = true;
      })
      return data
    }
  },
  watch:{
    proId(){
      if(this.authority.commit){
        this.getSelectpage();
      }
      this.getProject()
    },
  },
  created(){
    console.log(this.authority)
		if(this.authority.del){
			this.columns.push({
				title: '操作',
				  key: 'operation',
				  fixed: 'right',
				  width: 60
			})
		}
    if(this.authority.commit){
      this.getSelectpage();
    }
    this.getProject()
  },
  methods:{
    getExport(){
      this.$utils.api.downLoad({
        url:'/third_info/export',
        name:'第三方账号文档'
      })
    },
    async addNewSelect(){
      if(!this.newRegForm.name){
        this.$utils.msgErr('输入第三方名称');
        return
      }
      if(!this.newRegForm.reg_address){
        this.$utils.msgErr('输入注册地址');
        return
      }
      var res = await this.$utils.api.post({
        url:'/third_info/commit',
        data:{
          rows:[{
              ...this.newRegForm,
              'id':'',
              'info_id':'',
              'username':'',
              'password':'',
              'developer_id':'',
              'developer_pwd':'',
              'original_id':'',
              'merchant_key':'',
              'extends':'',
            }],
        },
        result:1
      })

      this.addOpen = !1;

      if(this.authority.commit){
        this.getSelectpage();
      }
      this.getProject()
    },
    async addSelect(){
      var subData = {},
        j = 0;
      this.selectpage.forEach(item=>{
        if(item.checked&&!this.disableD(item.name)){
          subData[j] = {
            'id':'',
            'info_id':item.id,
            'name':item.name,
            'reg_address':item.reg_address,
            'username':'',
            'password':'',
            'developer_id':'',
            'developer_pwd':'',
            'original_id':'',
            'merchant_key':'',
            'extends':'',
          }
		  j++
        }
      })
      var res = await this.$utils.api.post({
        url:'/third_info/commit',
        data:{
          rows:subData
        },
		result:1
      })
	  if(res.code == 1){
		  this.$utils.msgSuc('添加成功')
		  this.selectOpen = 0
	  }
      if(this.authority.commit){
        this.getSelectpage();
      }
      this.getProject()
    },
    async setSelect(){
      var subData = {},
        j = 0;
      subData = this.list.map(tem=>{
        return {
          'id':tem.id,
          'info_id':tem.info?tem.info.id:'',
          'name':tem.name,
          'reg_address':tem.reg_address,
          'username':tem.username,
          'password':tem.password,
          'developer_id':tem.developer_id,
          'developer_pwd':tem.developer_pwd,
          'original_id':tem.original_id,
          'merchant_key':tem.merchant_key,
          'extends':[(tem.extends0||''),(tem.extends1||'')],
        }
      })
      var res = await this.$utils.api.post({
        url:'/third_info/commit',
        data:{
          rows:subData
        },
		result:1
      })
      if(this.authority.commit){
		if(res.code == 1){
			this.$utils.msgSuc('提交成功')
		}
        this.getSelectpage();
      }
      this.getProject()
    },
    async getSelectpage(){
      var res = await this.$utils.api.get({
        url:'/third_info/selectpage',
        data:{},
      })
      this.selectpage = res&&res.list||[];
      if(this.list&&this.list.length){
        this.selectpage.forEach(item=>{
          if(this.list.find(ite=>ite.name==item.name)){
            item.checked = !0;
          }
        })
      }
    },
    async getProject(){
      var res = await this.$utils.api.get({
        url:'/third_info/index',
		result:1
      })
	  console.log("烦人a", res.data.rows)
	  if(res.code == 1){
		  this.list = res.data.rows.map((item,idx)=>{
		  				console.log("烦人",item)
		    return {
		      'index': (idx+1),
		      'id': item.id,
		  		  'sinfo':item.info,
		      'name': item.name,
		      'username': item.username,
		      'password': item.password,
		      'developer_id': item.developer_id,
		      'developer_pwd': item.developer_pwd,
		      'original_id': item.original_id,
		      'merchant_key': item.merchant_key,
		      'reg_address': item.reg_address,
		      'extends0': item.extends&&item.extends[0]?item.extends[0]:'',
		      'extends1': item.extends&&item.extends[1]?item.extends[1]:'',
		    }
		  });
		  if(this.selectpage&&this.selectpage.length){
		    this.selectpage.forEach(item=>{
		      if(this.list.find(ite=>ite.name==item.name)){
		        item.checked = !0;
		      }
		    })
		  }
	  }
    },
    setProId(id){
      if(!id){
        this.all = 1;
      }else{
        this.all = 0;
        this.$store.commit('setStore', {
          name: 'proId', 
          data: id
        })
      }
    },
	async copy(text){
		// 复制
		if(text){
			let res = await this.$utils.api.copyText(text)
			this.$utils.msgSuc(res,1)
		}
	},
	async delBtn(id,index){
		let res = await this.$utils.api.post({
		  url:'/third_info/del',
		  data:{
			  ids:id
		  },
		  result:1
		})
		if(res.code == 1){
			this.$utils.msgSuc('删除成功')
			console.log(this.list)
			// 删除成功后刷新复选框不选中
			for(let i in this.selectpage){
				if(this.list[index].sinfo){
					if(this.selectpage[i].id == this.list[index].sinfo.id){
						this.selectpage[i].checked = false
						break
					}
				}
			}
			this.getProject()
		}
	}
  }
};
</script>

<style scoped lang="scss">
.third{
  padding:20px;
  .posInput{
    position: absolute;
    top:5px;
    left:5px;
    right:5px;
    bottom:5px;
    border:5px;
    width:auto;
  }
  .stretch{
    display: flex;
    justify-content: space-between;
  }
  .projectSelect{
    line-height:30px;
  }
  .ant-dropdown-link{
    min-width:180px;
    overflow: hidden;
    span:after{
      margin-left:20px;
      content:'';
      display: inline-block;;
      border-top: 6px solid #aaa;
      border-left: transparent 6px solid;
      border-right: transparent 6px solid;
      position: relative;
      top:12px;
      float: right;
    }
  }
  .f-mr20{
    margin-right:20px;
  }
  .ttbox{
    flex:1;
  }
  .ttable{
    margin-top:0;
  }
  .documentFt{
    margin-top:20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.select-list{
  .item{
    display: inline-block;
    margin-right:30rpx;
    margin-bottom:30rpx;
    &.add{
      color:#00b7ee;
    }
  }
}
.caozuo{
	display: flex;
	justify-content: space-around;
	align-items: center;
	p{
		margin: 0;
		font-size: 12px;
		color: #407cff;
	}
	.clo9{
		color:#999;
	}
}
</style>